import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useThemeMode } from '../../../layout/theme-mode/ThemeModeProvider'
import { KTIcon } from '../../../../helpers'
import { getCSSVariableValue } from '../../../../assets/ts/_utils'
import moment from 'moment'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  data: any
}

const MixedWidgetIpStats: React.FC<Props> = ({ className, chartColor, chartHeight, data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, data?.ip_per_month))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    let chart;
    if (data) {
      chart = refreshChart()
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, data])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Vendor Statistics</span>
          <span className='text-muted fw-semibold fs-7'>{data.vendor_count} vendor registred</span>
        </h3>
      </div>

      <div className='card-body p-0 d-flex flex-column'>
        <div className='card-px pt-5 pb-10 flex-grow-1'>
          <div className='row g-0 mt-5 mb-10'>
            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-info'>
                    <KTIcon iconName='element-9' className='fs-1 text-info' />
                  </div>
                </div>
                <div>
                  <div className='fs-4 text-dark fw-bold'>{data.vendor_count}</div>
                  <div className='fs-7 text-muted fw-semibold'>Total Vendor</div>
                </div>
              </div>
            </div>

            {/* <div className='col'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-warning'>
                    <KTIcon iconName='element-9' className='fs-1 text-warning' />
                  </div>
                </div>

                <div>
                  <div className='fs-4 text-dark fw-bold'>{data?.ipaddresses_count?.pending}</div>
                  <div className='fs-7 text-muted fw-semibold'>Pending</div>
                </div>
              </div>
            </div> */}
          </div>

          <div className='row g-0'>
            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-success'>
                    <KTIcon iconName='check' className='fs-1 text-success' />
                  </div>
                </div>

                <div>
                  <div className='fs-4 text-dark fw-bold'>{data?.ip_blacklist_count?.whitelisted}</div>
                  <div className='fs-7 text-muted fw-semibold'>Whitelisted IP</div>
                </div>
              </div>
            </div>

            <div className='col'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light-danger'>
                    <KTIcon iconName='cross' className='fs-1 text-danger' />
                  </div>
                </div>

                <div>
                  <div className='fs-4 text-dark fw-bold'>{data?.ip_blacklist_count?.blacklisted}</div>
                  <div className='fs-7 text-muted fw-semibold'>Blacklisted IP</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref={chartRef} className='mixed-widget-6-chart card-rounded-bottom'></div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, data: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')

  return {
    series: [
      {
        name: '',
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: [
        moment(new Date()).subtract(5, 'months').format("MMM"),
        moment(new Date()).subtract(4, 'months').format("MMM"),
        moment(new Date()).subtract(3, 'months').format("MMM"),
        moment(new Date()).subtract(2, 'months').format("MMM"),
        moment(new Date()).subtract(1, 'months').format("MMM"),
        moment(new Date()).format("MMM")
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' IP Added'
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export { MixedWidgetIpStats }