import React from "react";
import { useNavigate } from "react-router-dom";
import { categorizeItemsByStatus, getClassNameByStatus } from "../../../../../app/utils/helper";

const TableWidgetUrlStats = ({ data }) => {
  const navigate = useNavigate();
  
  return (
    <div className="card card-flush h-xl-100">
      <div className="card-header py-7">
        <div
          className="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0"
        >
          <div
            className="fs-4 fw-bold pb-3"
          >
            Approved URLs
          </div>
        </div>
        <div className="card-toolbar">
          <a
            href='javascript:void(0)'
            type="button"
            className="btn btn-primary"
            onClick={() => navigate('/urlregistration')}
          >
            View All
          </a>
        </div>
      </div>
      <div className="card-body pt-1 table-responsive">
        <table
          className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3"
        >
          <thead>
            <tr>
              <th>URL</th>
              <th>Name</th>
              <th>Trusted</th>
              <th>Status</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {data?.slice(0,10)?.map((url) => {
              return (
                <tr>
                  <td className="min-w-175px">
                    <div className="position-relative ps-6 pe-3 py-2">
                      <div className={`position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-${url.is_blacklisted_by_tcil != null ? Boolean(url.is_blacklisted_by_tcil) ? 'danger' : 'success' : 'warning'}`} />
                      <a href='javascript:void(0)' className="mb-1 text-dark text-hover-primary fw-bold">
                        {url.url}
                      </a>
                      <div className="fs-7 text-muted fw-bold">
                        Updated on {url.updated_at}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="fw-bold">{url.name}</div>
                  </td>
                  <td>
                    <div className="fw-bold">
                      <span className={`badge badge-light-${url?.is_trusted ? 'success' : 'danger'}`}>
                        {url.is_trusted ? 'Yes' : 'No'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className={`badge badge-light-${url.is_blacklisted_by_tcil != null ? Boolean(url.is_blacklisted_by_tcil) ? 'danger' : 'success' : 'warning'}`}>
                      {url.is_blacklisted_by_tcil != null ? Boolean(url.is_blacklisted_by_tcil) ? 'Blacklist' : 'Whitelist' : 'Pending'}
                    </span>
                  </td>
                  <td className="">
                    <div className="fw-bold">{url.created_at}</div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TableWidgetUrlStats