import { FC, useEffect, useState } from 'react'
import { LayoutSplashScreen, PageLink, PageTitle, useLayout } from '../../../_transparency/layout/core'
import { EngageWidget10 } from '../../../_transparency/partials/widgets'
import { CardsWidget171 } from '../../../_transparency/partials/widgets/_new/cards/CardsWidget171'
import { KTIcon } from '../../../_transparency/helpers'
import { AUTH_LOCAL_STORAGE_KEY, axiosClient, axiosClientAuth } from '../../utils'
import moment from 'moment'

const urlRegistrationBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const getClassNameByStatus = (status: any) => {
  return status != null ? (Boolean(status) ? 'danger' : 'success') : 'warning'
}

const UrlRegistration = () => {
  const { setRightTolbar, urlRegistrationDateFilter } = useLayout()

  useEffect(() => {
    setRightTolbar('urlregistration')
  }, [])

  const [urlData, setUrlData] = useState({
    url_count: {
      "blacklisted": 0,
      "whitelisted": 0,
      "pending": 0,
      "total": 0
    },
    urls: []
  })
  const [done, setDone] = useState(false)

  useEffect(() => {
    axiosClientAuth
      .get('/main/urls')
      .then((resp) => {
        setUrlData(resp.data?.data)
        setDone(true)
      })
      .catch((error) => {
        console.log(error)
        setDone(true)
      })
  }, [])

  const [filteredData, setFilteredData] = useState({
    url_count: {
      "blacklisted": 0,
      "whitelisted": 0,
      "pending": 0,
      "total": 0
    },
    urls: []
  });

  useEffect(() => {
    if (urlRegistrationDateFilter.length == 0 || (urlRegistrationDateFilter[0] == '' && urlRegistrationDateFilter[1] == '')) {
      setFilteredData(urlData);
    } else {
      const temp: any = urlData?.urls.filter(
        (route: any) => {
          const routeDate = moment(new Date(route?.created_at)).format("YYYY-MM-DD")
          const startDate = moment(new Date(urlRegistrationDateFilter[0])).format("YYYY-MM-DD")
          const endDate = moment(new Date(urlRegistrationDateFilter[1])).format("YYYY-MM-DD")

          return routeDate >= startDate && routeDate <= endDate;
        }
      );

      const temp_count = {
        "blacklisted": 0,
        "whitelisted": 0,
        "pending": 0,
        "total": temp.length
      };

      for (const item of temp) {
        console.log(item);
        if (item.is_blacklisted_by_tcil === null) {
          temp_count.pending++;
        } else if (item.is_blacklisted_by_tcil == false) {
          temp_count.whitelisted++;
        } else {
          temp_count.blacklisted++;
        }
      }

      setFilteredData({ url_count: temp_count, urls: temp });
    }
  }, [urlRegistrationDateFilter, urlData]);

  return (
    <>
      <PageTitle breadcrumbs={urlRegistrationBreadCrumbs}>URL Registration</PageTitle>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-6 col-lg-6 col-xl-6'>
          {done && (
            <CardsWidget171
              className='h-lg-100 h-100'
              mLables={{
                Whitelist: filteredData.url_count?.whitelisted || 0,
                Blacklist: filteredData.url_count?.blacklisted || 0,
                Pending: filteredData.url_count?.pending || 0,
              }}
              mColors={{ Whitelist: 'bg-success', Blacklist: 'bg-danger', Pending: 'bg-warning' }}
              heading='URL Summary'
              subHeading='URLs'
            />
          )}
        </div>

        <div className='col-lg-6'>
          <EngageWidget10 className='h-md-100 bg-primary' />
        </div>
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {filteredData?.urls?.map((item: any) => (
          <div className='col-md-4'>
            <div className={`card card-flush px-8 py-5 position-relative overflow-hidden`}>
              {item?.is_trusted && <span className="activated-tag-url bg-success text-success fw-bold border-success bg-opacity-25">Trusted</span>}
              <div className='d-flex flex-stack mb-3'>
                <div className={`badge badge-light-${getClassNameByStatus(item.is_blacklisted_by_tcil)}`}>
                  {item.is_blacklisted_by_tcil != null
                    ? Boolean(!item.is_blacklisted_by_tcil)
                      ? 'Whitelist'
                      : 'Blacklist'
                    : 'Pending'
                  }
                </div>
              </div>
              <div
                className={`d-flex align-items-center bg-light-${getClassNameByStatus(
                  item.is_blacklisted_by_tcil
                )} rounded p-5 mb-7`}
              >
                <span className=' text-success me-5'>
                  <KTIcon
                    iconName='abstract-26'
                    className={`text-${getClassNameByStatus(item.is_blacklisted_by_tcil)} fs-1 me-5`}
                  />
                </span>
                <div className='flex-grow-1'>
                  <a href='javascript:void(0)' className='fw-bold text-gray-600 text-hover-primary fs-6'>
                    {item.name}
                  </a>
                  <span
                    className='text-white-800 fw-semibold d-block'
                    style={{ wordBreak: 'break-word' }}
                  >
                    {item.url}
                  </span>
                  <span className='text-muted fw-semibold d-block'>
                    {moment(item.created_at).format('DD MMM YYYY LT')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default UrlRegistration