import React, {useState} from 'react'
import {KTSVG} from '../../../../helpers'
import {tabs} from '../../../../../app/pages/supportCenter/Support'
import ModalCreateTicket from '../modal/ModalCreateTicket'

const EngageWidgetSupport = ({tab, setTab}) => {
  const [show, setShow] = useState(false)

  return (
    <div className='card mb-12'>
      <div className='card-body flex-column p-5'>
        <div className='card-rounded bg-light d-flex flex-stack flex-wrap p-5'>
          <ul className='nav flex-wrap border-transparent fw-bold'>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                  tab == tabs.overview && 'active'
                }`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.overview)}
              >
                Overview
              </a>
            </li>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                  tab == tabs.tickets && 'active'
                }`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.tickets)}
              >
                tickets
              </a>
            </li>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                  tab == tabs.faq && 'active'
                }`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.faq)}
              >
                FAQ
              </a>
            </li>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                  tab == tabs.contactUs && 'active'
                }`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.contactUs)}
              >
                Contact US
              </a>
            </li>
          </ul>
          <a
            href='javascript:void(0)'
            className='btn btn-primary fw-bold fs-8 fs-lg-base'
            onClick={() => setShow(true)}
          >
            Create Ticket
          </a>
        </div>
      </div>
      <ModalCreateTicket show={show} setShow={setShow} />
    </div>
  )
}

export default EngageWidgetSupport
