import React from "react";
import { Outlet } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_transparency/helpers";
import { useLayout } from "../../../_transparency/layout/core";

const PaymentLayout = () => {
  const { paymentLayoutHeading, paymentLayoutDescription } = useLayout();

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-lg-row-fluid bgi-size-cover bgi-position-center payment-layout-img' style={{ width: '35%' }}>
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <img
            className='mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/illustrations/sigma-1/12.png')}
            alt=''
          />
          <h1 className='fs-2qx fw-bolder text-center mb-3'>
            {paymentLayoutHeading}
          </h1>
          <div className='fs-4 mb-7 text-center'>{paymentLayoutDescription}</div>
          <div className='text-gray-600 fs-base text-center fw-semibold'>
            Transparency Data reservoir makes available and provides{' '}
            <a href='#' className='opacity-75-hover text-primary me-1'>
              Transparency
            </a>{' '}
            on the DLR reports and{' '}
            <a href='#' className='opacity-75-hover text-primary me-1'>
              scrubbing reports
            </a>{' '}
            of the SMS campaigns sent by an Enterprise.
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-lg-row-fluid p-10' style={{ width: '65%' }}>
        <div className='rounded-4 d-flex flex-lg-row-fluid align-items-center justify-content-center' style={{ backgroundColor: '#fdf1e7' }}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default PaymentLayout