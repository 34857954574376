import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { KTIcon } from "../../../_transparency/helpers";
import { useRegistration } from "../../utils/customHooks/useRegistration";

const RegistrationModal = ({ show, handleClose }) => {
  const { registerNow } = useRegistration()

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <h3 className='fs-2x fw-semibold mb-0'>Register Now</h3>
      </Modal.Header>
      <Modal.Body>
        <div className='row align-items-center'>
          <div className='col-sm-7 pe-0 mb-5 mb-sm-0'>
            <div className='d-flex justify-content-between h-100 flex-column ps-xl-7'>
              <div className='mb-10'>
                <div className='mb-10'>
                  <span className='fs-1 fw-semibold opacity-75'>
                    Join us today and kickstart your<br />
                    journey with our secure  SMS service!
                  </span>
                </div>
                <div className='d-flex align-items-center flex-wrap d-grid gap-2'>
                  <div className='d-flex align-items-center me-5 me-xl-13'>
                    <div className='symbol symbol-30px symbol-circle me-3'>
                      <span className='symbol-label'>
                        <KTIcon iconName='abstract-41' className='fs-2' />
                      </span>
                    </div>
                    <div className='m-0'>
                      <div className='text-opacity-75 fs-8'>
                        Register
                      </div>
                      <span className='fw-bold fs-7 d-block'>Secure URLs</span>
                    </div>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-30px symbol-circle me-3'>
                      <span className='symbol-label'>
                        <KTIcon iconName='abstract-26' className='fs-3' />
                      </span>
                    </div>
                    <div className='m-0'>
                      <div className='text-opacity-75 fs-8'>
                        Purchase
                      </div>
                      <span className='fw-bold fs-7 d-block'>SMS Pack</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-5'>
                <a
                  href='javascript:void(0)'
                  className='btn btn-color-white bg-primary bg-hover-opacity-75 fw-semibold'
                  onClick={registerNow}
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
          <div className='col-sm-5'>
            <img
              src='/media/svg/illustrations/easy/7.svg'
              className='h-200px h-lg-250px my-n6'
              alt=''
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RegistrationModal