import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { KTIcon } from "../../../../helpers";
import Swal from "sweetalert2";
import { axiosClientAuth } from "../../../../../app/utils";
import { categorizeItemsByStatus, getClassNameByStatus } from "../../../../../app/utils/helper";
import { useLayout } from "../../../../layout/core";

const TableWidgetIpList = () => {
  const [data, setData] = useState([])
  const [filterData, setFilterData] = useState([]);

  const { ipRegistrationDateFilter } = useLayout()

  const getMyData = () => {
    axiosClientAuth
      .get('/auth/vendors')
      .then((res) => {
        setData(res.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (ipRegistrationDateFilter.length == 0 || (ipRegistrationDateFilter[0] == '' && ipRegistrationDateFilter[1] == '')) {
      setFilterData(data);
    } else {
      const temp: any = data?.filter((route: any) => {
        const routeDate = moment(route?.created_at).format('YYYY-MM-DD');
        const startDate = moment(new Date(ipRegistrationDateFilter[0])).format('YYYY-MM-DD');
        const endDate = moment(new Date(ipRegistrationDateFilter[1])).format('YYYY-MM-DD');

        return routeDate >= startDate && routeDate <= endDate;
      });

      setFilterData(temp);
    }
  }, [ipRegistrationDateFilter, data]);

  useEffect(() => {
    getMyData()
  }, [])

  const columns: ReadonlyArray<Column<any>> = useMemo(
    () => [
      {
        Header: 'DATE',
        accessor: 'created_at',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{moment(props.value).format('DD MMM YYYY')}</span>
              <span className='d-block text-muted fw-semibold'>
                {moment(props.value).format('LT')}
              </span>
            </>
          )
        },
      },
      {
        Header: 'Vendor Name',
        accessor: 'name',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{props.value}</span>
            </>
          )
        },
      },
      {
        Header: 'Vendor Type',
        accessor: 'node',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{props.value?.node_name}</span>
            </>
          )
        },
      },
      {
        Header: 'Total IP',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{props.row.original?.ips_count?.total}</span>
            </>
          )
        },
      },
      {
        Header: 'Whitelisted IP',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{props.row.original?.ips_count?.whitelisted}</span>
            </>
          )
        },
      },
      {
        Header: 'Blacklisted IP',
        Cell: ({ ...props }) => {
          return (
            <>
              <span>{props.row.original?.ips_count?.blacklisted}</span>
            </>
          )
        },
      },
    ], [])

  const table = useTable(
    {
      columns: columns as readonly Column<object>[],
      data: filterData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <div className='card card-flush'>
      <div className='card-header mt-5'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Vendor List</h3>
          <div className='fs-6 text-gray-400'>Total {filterData.length} vendors</div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search Vendor'
              value={table.state.globalFilter}
              onChange={(e) => table.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...table.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {table.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-white ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-white ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...table.getTableBodyProps()}>
              {(table.page.length > 0 &&
                table.page.map((row) => {
                  table.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={table.state.pageSize}
                onChange={(e) => table.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {table.state.pageIndex + 1} of {table.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => table.previousPage()}
                disabled={!table.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-white fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => table.nextPage()}
                disabled={!table.canNextPage}
              >
                <KTIcon iconName='right' className='text-white fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableWidgetIpList