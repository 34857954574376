import React, { useEffect, useMemo, useState } from 'react'
import { PageLink, PageTitle, useLayout } from '../../../_transparency/layout/core'
import { useIntl } from 'react-intl'
import { CardsWidget171 } from '../../../_transparency/partials/widgets/_new/cards/CardsWidget171'
import { EngageWidget10 } from '../../../_transparency/partials/widgets'
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { KTIcon } from '../../../_transparency/helpers'
import moment from 'moment'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { axiosClientAuth } from '../../utils'
import Swal from 'sweetalert2'

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export default function MyIP() {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [done, setDone] = useState(false)

  const { setRightTolbar, myIpDateFilter } = useLayout();

  useEffect(() => {
    setRightTolbar('myip')
  }, [])

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [currentData, setCurrentData] = useState<any>([])

  useEffect(() => {
    axiosClientAuth
      .get('/main/pe-ip')
      .then((res) => {
        setData(res.data?.data)
      })
      .catch((error) => {
        console.log(error)
        setDone(true)
      })
  }, [])

  const onStatusChange = (item: any, type: Boolean) => {
    axiosClientAuth
      .patch('/main/ip-address', {
        uuid: item.uuid,
        status: type,
      })
      .then((resp) => {
        Swal.fire({
          titleText: 'Success!',
          text: 'Status Changed Successfully.',
          icon: 'success',
          confirmButtonText: 'Ok! Got It',
        }).then((isConfirm) => {
          if (isConfirm) {
            window.location.reload()
          }
        })
      })
      .catch((error) => {
        Swal.fire({
          titleText: 'Error!',
          text: 'Internal Server Error!!!',
          icon: 'error',
          confirmButtonText: 'Ok! Got It',
        })
      })
  }

  useEffect(() => {
    if (myIpDateFilter.length == 0 || (myIpDateFilter[0] == '' && myIpDateFilter[1] == '')) {
      setFilteredData(data);
    } else {
      const temp: any = data?.filter(
        (route: any) => {
          const routeDate = moment(new Date(route?.created_at)).format("YYYY-MM-DD")
          const startDate = moment(new Date(myIpDateFilter[0])).format("YYYY-MM-DD")
          const endDate = moment(new Date(myIpDateFilter[1])).format("YYYY-MM-DD")

          return routeDate >= startDate && routeDate <= endDate;
        }
      );

      setFilteredData(temp);
    }
  }, [myIpDateFilter, data]);

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>My IPs</PageTitle>

      <MyTable
        setShow={setShow}
        data={filteredData}
        setCurrentData={setCurrentData}
        onStatusChange={onStatusChange}
      />

      {/* My Modal */}
      <Modal
        className='modal-sticky modal-lg modal-sticky-lg modal-sticky-bottom-right'
        show={show}
        animation={true}
        centered={true}
      >
        <div className='modal-header'>
          <h2>Route Info</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setShow(false)}
          >
            <KTIcon iconName='cross' className='text-gray fs-1' />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
            data-kt-stepper='true'
          >
            <div
              className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'
              id='routebox'
            >
              <div className='stepper-nav ps-lg-10'>
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <span className='menu-icon'>
                        <span className='svg-icon svg-icon-2'>
                          <i className='fa fa-route text-white' />
                        </span>
                      </span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>{currentData?.name}</h3>
                      <div className='stepper-desc'>{moment(currentData?.created_at).format("DD MMM YYYY LT")}</div>
                    </div>
                  </div>
                  <div className='stepper-line h-40px' />
                </div>
                {currentData?.ips_obj?.map((item, index) => (
                  <div key={index} className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check' />
                        <span className='stepper-number'>{index + 1}</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>
                          {item?.vendor_name}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-manage-ip-${item?.address}`}>
                                {item?.address}
                              </Tooltip>
                            }
                          >
                            <i className='fas fa-exclamation-circle ms-2 fs-7' />
                          </OverlayTrigger>
                        </h3>
                        <span className='text-muted'>{item?.name}</span>
                        {/* <div className='stepper-desc'>{item?.address}</div> */}
                      </div>
                    </div>
                    {index !== currentData?.ips_obj?.length - 1 && (
                      <div className='stepper-line h-40px' />
                    )}
                  </div>
                ))}
                {/* <div className='stepper-item mark-completed' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check' />
                      <span className='stepper-number'>2</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title' title='0.9.8.7'>
                        Not Found
                        <KTIcon iconName='information-2' className='text-white fs-3 ms-2' />
                      </h3>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <img
                style={{ width: '350px', transform: 'scaleX(-1)' }}
                src='/media/illustrations/sketchy-1/5.png'
                alt=''
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const MyTable = ({ setShow, data, setCurrentData, onStatusChange }) => {
  const columns: ReadonlyArray<Column<any>> = useMemo(() => [
    { Header: 'ROUTE NAME', accessor: 'name' },
    { Header: 'IP ADDRESS', accessor: 'address' },
    {
      Header: 'DATE',
      accessor: 'created_at',
      Cell: ({ ...props }) => {
        return (
          <>
            <span>{moment(props.value).format('DD MMM YYYY')}</span>
            <span className='d-block text-muted fw-semibold'>
              {moment(props.value).format('LT')}
            </span>
          </>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      Cell: ({ ...props }) => {
        return props.value == 'true' || Boolean(props.value) == true ? (
          <span className='badge badge-light-success fw-bold px-4 py-3'>Enabled</span>
        ) : (
          <span className='badge badge-light-danger fw-bold px-4 py-3'>Disabled</span>
        )
      },
    },
    {
      Header: 'TCIL STATUS',
      accessor: 'is_blacklisted_by_tcil',
      Cell: ({ ...props }) => {
        return props.value == 'false' || Boolean(props.value) == false ? (
          <span className='badge badge-light-success fw-bold px-4 py-3'>Whitelisted</span>
        ) : (
          <span className='badge badge-light-danger fw-bold px-4 py-3'>Blacklisted</span>
        )
      },
    },
    {
      Header: 'ACTION',
      Cell: ({ ...props }) => {
        return (
          <>
            {/* <button
              className='btn py-0 h-35px btn btn-light-info me-3'
              onClick={() => {
                setShow(true)
                setCurrentData(props.row.original)
              }}
            >
              <span className='fs-7'>View</span>
            </button> */}
            <button
              className='btn h-35px btn-icon btn btn-light-primary me-3'
              onClick={() => {
                onStatusChange(props.row.original, true)
              }}
              disabled={Boolean(props?.row?.original?.status)}
            >
              <KTIcon iconName='check' className='text-primary text-hover-white fs-1' />
            </button>
            <button
              className='btn h-35px btn-icon btn btn-light-danger'
              onClick={() => {
                onStatusChange(props.row.original, false)
              }}
              disabled={
                props?.row?.original?.status != null && !Boolean(props?.row?.original?.status)
              }
            >
              <KTIcon iconName='cross' className='text-danger text-hover-white fs-1' />
            </button>
          </>
        )
      },
    },
  ], [])

  const mTable = useTable(
    {
      columns,
      data: data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <div className={`card card-flush`}>
      <div className='card-header mt-5'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>IP List</h3>
          <div className='fs-6 text-gray-400'>{mTable.rows.length} Total IP</div>
        </div>
        <div className='d-flex align-items-center'>
          <select
            className='form-select form-select-sm form-select-solid'
            value={mTable.headers[3].filterValue}
            onChange={(e) => mTable.headers[3].setFilter(e.target.value)}
          >
            {['All', 'true', 'false'].map((item) => (
              <option value={item == 'All' ? '' : item}>
                {item == 'true' ? 'Enabled' : item == 'false' ? 'Disabled' : 'All'}
              </option>
            ))}
          </select>
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search Order'
              value={mTable.state.globalFilter}
              onChange={(e) => mTable.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...mTable.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {mTable.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'ACTION' ? 'w-200px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-white ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-white ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...mTable.getTableBodyProps()}>
              {(mTable.page.length > 0 &&
                mTable.page.map((row) => {
                  mTable.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={mTable.state.pageSize}
                onChange={(e) => mTable.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {mTable.state.pageIndex + 1} of {mTable.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => mTable.previousPage()}
                disabled={!mTable.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-white fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => mTable.nextPage()}
                disabled={!mTable.canNextPage}
              >
                <KTIcon iconName='right' className='text-white fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
