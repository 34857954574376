import React from "react";
import { axiosClientAuth } from "../axiosClient";
import { useLayout } from "../../../_transparency/layout/core";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const useRegistration = () => {
  const navigate = useNavigate()

  const {
    setIframe,
    setPaymentLayoutHeading,
    setPaymentLayoutDescription
  } = useLayout()

  const registerNow = async () => {
    try {
      const res = await axiosClientAuth.post('/payments/init-payment', {
        paid_for: 'user_registration',
      })
      console.log(res)
      setIframe(res.data.data.iframe_url);
      setPaymentLayoutHeading('User Registration');
      setPaymentLayoutDescription('Unlock the full potential of our secure SMS service by completing the registration process');
      navigate('/payment');
    } catch (error:any) {
      Swal.fire({
        titleText: 'Error!',
        text: error?.response?.data?.message,
        icon: 'error',
        confirmButtonText: 'Ok! Got It',
        timer: 2000,
        timerProgressBar: true,
      }).then(resp=>{
        window.location.href = "/myprofile"
      })
    }
  }

  return { registerNow }
}