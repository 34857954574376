/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from 'react-router-dom'
import { KTIcon, toAbsoluteUrl } from '../../../../helpers'
import { useLayout } from '../../../../layout/core'
import { useRegistration } from '../../../../../app/utils/customHooks/useRegistration'

type Props = {
  className: string
}

const EngageWidget10 = ({ className }: Props) => {
  const navigate = useNavigate()

  const { isRegistred } = useLayout()

  const { registerNow } = useRegistration()

  return (
    isRegistred
      ? <div className={`card card-flush ${className}`}>
        <div className='card-body'>
          <div className='row align-items-center'>
            <div className='col-sm-6 pe-0 mb-5 mb-sm-0'>
              <div className='d-flex justify-content-between h-100 flex-column pt-xl-5 pb-xl-2 ps-xl-7'>
                <div className='mb-7'>
                  <div className='mb-6'>
                    <h3 className='fs-2x fw-semibold text-white'>Upgrade Your Plan</h3>
                    <span className='fw-semibold text-white opacity-75'>
                      Try our all new Environment <br />
                      with Pro Plan
                    </span>
                  </div>
                  <div className='d-flex align-items-center flex-wrap d-grid gap-2'>
                    <div className='d-flex align-items-center me-5 me-xl-13'>
                      <div className='symbol symbol-30px symbol-circle me-3'>
                        <span
                          className='symbol-label'
                          style={{ background: 'rgba(255, 255, 255, 0.15)' }}
                        >
                          <KTIcon iconName='abstract-41' className='text-white fs-2' />
                        </span>
                      </div>
                      <div className='m-0'>
                        <a href="javascript:void(0)" className='text-white text-opacity-75 fs-8'>
                          Register
                        </a>
                        <span className='fw-bold text-white fs-7 d-block'>Unlimited URLs</span>
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-30px symbol-circle me-3'>
                        <span
                          className='symbol-label'
                          style={{ background: 'rgba(255, 255, 255, 0.15)' }}
                        >
                          <KTIcon iconName='abstract-26' className='text-white fs-3' />
                        </span>
                      </div>
                      <div className='m-0'>
                        <a href="javascript:void(0)" className='text-white text-opacity-75 fs-8'>
                          Flexible
                        </a>
                        <span className='fw-bold text-white fs-7 d-block'>URLs Mapping</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='m-0'>
                  {/* <a
                    href='javascript:void(0)'
                    className='btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold'
                    onClick={() => navigate('/smspack')}
                  >
                    Upgrade Plan
                  </a> */}
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <img
                src='/media/svg/illustrations/easy/7.svg'
                className='h-200px h-lg-250px my-n6'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
      : <div className={`card card-flush ${className}`}>
        <div className='card-body'>
          <div className='row align-items-center'>
            <div className='col-sm-6 pe-0 mb-5 mb-sm-0'>
              <div className='d-flex justify-content-between h-100 flex-column pt-xl-5 pb-xl-2 ps-xl-7'>
                <div className='mb-7'>
                  <div className='mb-6'>
                    <h3 className='fs-2x fw-semibold text-white'>Register Now</h3>
                    <span className='fw-semibold text-white opacity-75'>
                      Join us today and kickstart your<br />
                      journey with our secure  SMS service!
                    </span>
                  </div>
                  <div className='d-flex align-items-center flex-wrap d-grid gap-2'>
                    <div className='d-flex align-items-center me-5 me-xl-13'>
                      <div className='symbol symbol-30px symbol-circle me-3'>
                        <span
                          className='symbol-label'
                          style={{ background: 'rgba(255, 255, 255, 0.15)' }}
                        >
                          <KTIcon iconName='abstract-41' className='text-white fs-2' />
                        </span>
                      </div>
                      <div className='m-0'>
                        <a href="javascript:void(0)" className='text-white text-opacity-75 fs-8'>
                          Register
                        </a>
                        <span className='fw-bold text-white fs-7 d-block'>Secure URLs</span>
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-30px symbol-circle me-3'>
                        <span
                          className='symbol-label'
                          style={{ background: 'rgba(255, 255, 255, 0.15)' }}
                        >
                          <KTIcon iconName='abstract-26' className='text-white fs-3' />
                        </span>
                      </div>
                      <div className='m-0'>
                        <a href="javascript:void(0)" className='text-white text-opacity-75 fs-8'>
                          Purchase
                        </a>
                        <span className='fw-bold text-white fs-7 d-block'>SMS Pack</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='m-0'>
                  <a
                    href='javascript:void(0)'
                    className='btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold'
                    onClick={registerNow}
                  >
                    Register Now
                  </a>
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <img
                src='/media/svg/illustrations/easy/7.svg'
                className='h-200px h-lg-250px my-n6'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
  )
}

export { EngageWidget10 }