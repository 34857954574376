import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../helpers'
import {axiosClientAuth} from '../../../../../app/utils'
import Swal from 'sweetalert2'
import {useDropzone} from 'react-dropzone'

const ModalCreateTicket = ({show, setShow}) => {
  const [input, setInput] = useState({
    title: '',
    description: '',
    issue_type: '',
  })

  const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone()

  const onSubmitForm = (e) => {
    e.preventDefault()
    const formData = new FormData()
    Object.keys(input).forEach((key) => {
      formData.append(key, input[key])
    })
    if (acceptedFiles.length > 0) {
      formData.append('attachment', acceptedFiles[0])
    }
    if (input.issue_type && input.title && input.description) {
      axiosClientAuth
        .post('/support/tcil-ticket', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((resp) => {
          Swal.fire({
            titleText: 'Success!',
            text: 'Support Ticket Created Successfully.',
            icon: 'success',
            confirmButtonText: 'Ok! Got It',
          }).then((isConfirm) => {
            if (isConfirm) {
              window.location.reload()
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      Swal.fire({
        titleText: 'Error!',
        text: 'Title, Issue Type, Description are required!!!',
        icon: 'error',
        confirmButtonText: 'Ok! Got It',
        timer: 2000,
        timerProgressBar: true,
      })
    }
  }

  return (
    <Modal
      className='modal-sticky modal-lg modal-sticky-lg modal-sticky-bottom-right'
      id='kt_inbox_compose'
      role='dialog'
      aria-hidden='true'
      tabIndex='-1'
      show={show}
      animation={true}
      centered={true}
    >
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='d-flex justify-content-end'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setShow(false)}
          >
            <KTIcon iconName='cross' className='text-gray fs-1' />
          </div>
        </div>
        <form id='kt_modal_new_ticket_form' onSubmit={onSubmitForm} className='form'>
          <div className='mb-13 text-center'>
            <h1 className='mb-3'>Create Ticket</h1>
            <div className='text-gray-400 fw-semibold fs-5'>
              If you need more info, please check{' '}
              <a href='#' className='fw-bold link-primary'>
                Support Guidelines
              </a>
              .
            </div>
          </div>
          <div className='d-flex flex-column mb-8 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
              <span className='required'>Title</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify a Tile for your issue'
              />
            </label>
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Enter your ticket title'
              name='title'
              value={input.title}
              onChange={(e) => {
                setInput((prev) => ({...prev, title: e.target.value}))
              }}
            />
          </div>
          <div className='row g-9 mb-8'>
            <div className='col-md-12 fv-row'>
              <label className='required fs-6 fw-semibold mb-2'>Issue Type</label>
              <select
                className='form-select form-select-solid'
                name='product'
                value={input.issue_type}
                onChange={(e) => {
                  setInput((prev) => ({...prev, issue_type: e.target.value}))
                }}
              >
                <option value=''>Select a Issue...</option>
                <option value='general'>General</option>
                <option value='technical'>Technical</option>
                <option value='buisness'>Business</option>
              </select>
            </div>
          </div>
          <div className='d-flex flex-column mb-8 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
              <span className='required'>Description</span>
            </label>
            <textarea
              className='form-control form-control-solid'
              placeholder='Enter Description'
              name='description'
              value={input.description}
              onChange={(e) => {
                setInput((prev) => ({...prev, description: e.target.value}))
              }}
            />
          </div>
          <div className='fv-row mb-8'>
            <label className='fs-6 fw-semibold mb-2'>Attachments</label>

            <div
              {...getRootProps({isDragActive})}
              className='dropzone'
              id='kt_modal_create_ticket_attachments'
            >
              <input {...getInputProps()} />
              <div className='dz-message needsclick align-items-center'>
                <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      opacity='0.3'
                      d='M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z'
                      fill='currentColor'
                    />
                    <path
                      d='M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z'
                      fill='currentColor'
                    />
                    <path
                      d='M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z'
                      fill='currentColor'
                    />
                    <path d='M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z' fill='currentColor' />
                  </svg>
                </span>
                <div className='ms-4'>
                  <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                    Drop files here or click to upload.
                  </h3>
                  <span className='fw-semibold fs-7 text-gray-400'>Upload up to 1 MB file</span>
                </div>
              </div>
              <div className='mt-2' style={{textAlign: 'left'}}>
                {acceptedFiles.map((file: any) => {
                  return <>{file?.path}</>
                })}
              </div>
            </div>
          </div>
          <div className='mb-15 fv-row'>
            <div className='d-flex flex-stack'>
              <div className='fw-semibold me-5'>
                <label className='fs-6'>Notifications</label>
                <div className='fs-7 text-gray-400'>Allow Notifications by Phone or Email</div>
              </div>
              <div className='d-flex align-items-center'>
                <label className='form-check form-check-custom form-check-solid me-10'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    name='notifications[]'
                    defaultValue='email'
                    // defaultChecked="checked"
                  />
                  <span className='form-check-label fw-semibold'>Email</span>
                </label>
                <label className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-20px w-20px'
                    type='checkbox'
                    name='notifications[]'
                    defaultValue='phone'
                  />
                  <span className='form-check-label fw-semibold'>Phone</span>
                </label>
              </div>
            </div>
          </div>
          <div className='text-center'>
            <button
              type='reset'
              id='kt_modal_new_ticket_cancel'
              className='btn btn-light me-3'
              onClick={() => {
                setShow(false);
                setInput({title: '',
                description: '',
                issue_type: '',})
              }}
            >
              Cancel
            </button>
            <button type='submit' id='kt_modal_new_ticket_submit' className='btn btn-primary'>
              <span className='indicator-label'>Submit</span>
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModalCreateTicket
