/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle, useLayout } from '../../../_transparency/layout/core'
import EngageWidgetSupport from '../../../_transparency/partials/widgets/_new/engage/EngageWidgetSupport'
import Tickets from '../../modules/apps/support/Tickets'
import FAQ from '../../modules/apps/support/FAQ'
import ContactUs from '../../modules/apps/support/ContactUs'
import OverviewTickets from '../../modules/apps/support/OverviewTickets'

export enum tabs {
  'overview' = 'overview',
  'tickets' = 'tickets',
  'faq' = 'faq',
  'contactUs' = 'contactUs'
}

const SupportCenter: FC = () => {
  const [tab, setTab] = useState(tabs.overview);

  let [tabComponent, setTabComponent] = useState(<OverviewTickets />);

  useEffect(() => {
    switch (tab) {
      case tabs.overview:
        setTabComponent(<OverviewTickets />);
        break;
      case tabs.tickets:
        setTabComponent(<Tickets />);
        break;
      case tabs.faq:
        setTabComponent(<FAQ />);
        break;
      case tabs.contactUs:
        setTabComponent(<ContactUs />);
        break;
    }
  }, [tab])

  return (
    <>
      <EngageWidgetSupport tab={tab} setTab={setTab} />
      {tabComponent}
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/support',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const SupportCenterWrapper: FC = () => {
  const intl = useIntl()
  const { setRightTolbar } = useLayout();

  useEffect(() => {
    setRightTolbar("Support Center")
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>Ticket Support</PageTitle>
      <SupportCenter />
    </>
  )
}

export { SupportCenterWrapper }