/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from 'react'
import { KTIcon } from '../../../../helpers'
import { getCSS, getCSSVariableValue } from '../../../../assets/ts/_utils'
import { useThemeMode } from '../../../layout/theme-mode/ThemeModeProvider'
import ApexCharts from 'apexcharts'

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  data?: any
}

const CardsWidgetUrlSummary: FC<Props> = ({
  className,
  chartSize = 120,
  chartLine = 11,
  chartRotate = 145,
  data,
}) => {

  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  useEffect(() => {
    let chart;
    if (data?.pending) {
      chart = refreshChart()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, data])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height')) + 10

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, chartSize, {
        Whitelist: data?.whitelisted,
        Blacklist: data?.blacklisted,
        Pending: data?.pending
      }, { Whitelist: 'bg-success', Blacklist: 'bg-danger', Pending: 'bg-warning' })
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            {/* <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span> */}

            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{data?.total}</span>

            <span className='badge badge-light-success fs-base'>
              <KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' /> 2.2%
            </span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>Total URLs</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-3 pt-2'>
          {/* <div
            id='kt_card_widget_url_chart'
            ref={chartRef}
            style={{ minWidth: chartSize + 'px', minHeight: chartSize + 'px' }}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          /> */}
          <div
            ref={chartRef}
            className='mt-2'
            id='kt_card_widget_17_chart'
            style={{ height: chartSize + 'px' }}
          />
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Whitelist</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{data?.whitelisted}</div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='bullet w-8px h-3px rounded-2 bg-danger me-3'></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Blacklist</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{data?.blacklisted}</div>
          </div>
          <div className='d-flex fw-semibold align-items-center'>
            <div
              className='bullet w-8px h-3px bg-warning rounded-2 me-3'
            ></div>
            <div className='text-gray-500 flex-grow-1 me-4'>Pending</div>
            <div className=' fw-bolder text-gray-700 text-xxl-end'>{data?.pending}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

// const initChart = function (
//   chartSize: number = 70,
//   chartLine: number = 11,
//   chartRotate: number = 145,
//   data
// ) {
//   const el = document.getElementById('kt_card_widget_url_chart')
//   if (!el) {
//     return
//   }
//   el.innerHTML = ''

//   var options = {
//     size: chartSize,
//     lineWidth: chartLine,
//     rotate: chartRotate,
//     //percent:  el.getAttribute('data-kt-percent') ,
//   }

//   const canvas = document.createElement('canvas')
//   const span = document.createElement('span')

//   // @ts-ignore
//   if (typeof G_vmlCanvasManager !== 'undefined') {
//     // @ts-ignore
//     G_vmlCanvasManager.initElement(canvas)
//   }

//   const ctx = canvas.getContext('2d')
//   canvas.width = canvas.height = options.size

//   el.appendChild(span)
//   el.appendChild(canvas)

//   // @ts-ignore
//   ctx.translate(options.size / 2, options.size / 2) // change center
//   // @ts-ignore
//   ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

//   //imd = ctx.getImageData(0, 0, 240, 240);
//   const radius = (options.size - options.lineWidth) / 2

//   const drawCircle = function (color: string, lineWidth: number, percent: number) {
//     percent = Math.min(Math.max(0, percent || 1), 1)
//     if (!ctx) {
//       return
//     }

//     ctx.beginPath()
//     ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
//     ctx.strokeStyle = color
//     ctx.lineCap = 'round' // butt, round or square
//     ctx.lineWidth = lineWidth
//     ctx.stroke()
//   }

//   // Init 2
//   drawCircle('#E4E6EF', options.lineWidth, data?.pending / (data?.total))
//   drawCircle(getCSSVariableValue('--bs-danger'), options.lineWidth, data?.blacklisted / (data?.total))
//   drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, data?.whitelisted / data?.total)
// }

function getChartOptions(height: number, width: number, mLables: any, mColors: Object) {
  return {
    series: Object.values(mLables),
    labels: Object.keys(mLables),
    colors: Object.values(mColors).map((item) => {
      return getCSSVariableValue(`--${item?.toString().replace('bg', 'bs')}`)
    }),
    chart: {
      height,
      width,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height,
            width,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  }
}

export { CardsWidgetUrlSummary }