import React from 'react';
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'

export function MenuInner() {
  return (
    <>
      <MenuItem title='Dashboard' to='/dashboard' />
      <MenuInnerWithSub title='URL Management' to='/url' menuPlacement='bottom-start' menuTrigger='click'>
        <MenuItem title='URL Registration' to='/urlregistration' icon='fasten' />
        <MenuItem title='URL Mapping' to='/urlmapping' icon='focus' />
      </MenuInnerWithSub>
      <MenuItem title='Vendor List' to='/vendorlist' />
      <MenuItem title='My IP' to='/myip' />
      <MenuItem title='Manage Routes' to='/manageroutes' />
      <MenuItem title='UCC Complaints' to='/ucccomplaints' />
      {/* <MenuItem title='SMS Pack' to='/smspack' /> */}
      <MenuItem title='Order List' to='/orderslist' />
      <MenuItem title='Tickets' to='/support' />
    </>
  )
}