import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_transparency/helpers'
import {ChatInner} from '../../../../_transparency/partials'
import {axiosClientAuth} from '../../../utils'
import {Card1} from '../../../../_transparency/partials/content/cards/Card1'

const Tickets = () => {
  const [data, setData] = useState([])
  const [activeTicket, setActiveTicket] = useState<any>(null)
  const [ticketChats, setTicketChats] = useState<any>([])

  useEffect(() => {
    axiosClientAuth
      .get('/support/tcil-ticket')
      .then((resp) => setData(resp.data.data))
      .catch(() => {})
  }, [])

  return (
    <div>
      {/* <div className='d-flex flex-column flex-xl-row p-7'>
          <div className='flex-lg-row-fluid me-xl-15 mb-20 mb-xl-0'>
            <div className='mb-0'>
              <form method='post' action='#' className='form mb-15'>
                <div className='position-relative'>
                  <span className='svg-icon svg-icon-1 svg-icon-primary position-absolute top-50 translate-middle ms-9'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen021.svg'
                      className='svg-icon-primary'
                    />
                  </span>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid ps-14'
                    name='search'
                    defaultValue=''
                    placeholder='Search'
                  />
                </div>
              </form>
              <h1 className='text-dark mb-10'>My Tickets</h1>
              <div style={{maxHeight: '350px', overflow: 'scroll'}}>
                {data.map((item: any, index: number) => (
                  <div
                    key={index}
                    className='d-flex mb-10 cursor-pointer'
                    onClick={() => onTicketClick(item)}
                  >
                    <span className='svg-icon svg-icon-2x me-5 ms-n1 mt-2 svg-icon-success'>
                      <KTIcon iconName='archive-tick' className='text-success fs-1' />
                    </span>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='text-dark text-hover-primary fs-4 me-3 fw-semibold'>
                          {item.title}
                        </span>
                        {item.status == 'open' ? (
                          <span className='badge badge-danger my-1'>Open</span>
                        ) : item.status == 'resolved' ? (
                          <span className='badge badge-success my-1'>Resolved</span>
                        ) : (
                          <span className='badge badge-warning my-1'>Processing</span>
                        )}
                      </div>
                      <span className='text-muted fw-semibold fs-6'>{item.description}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10 w-100 mw-lg-350px mw-xxl-500px'>
            {activeTicket && (
              <div className='card' id='kt_chat_messenger'>
                <div className='card-header' id='kt_chat_messenger_header'>
                  <div className='card-title'>
                    <div className='symbol-group symbol-hover'></div>
                    <div className='d-flex justify-content-center flex-column me-3'>
                      <span className='fs-4 fw-bolder text-gray-900 me-1 mb-2 lh-1'>
                        Support Chat
                      </span>

                    </div>
                  </div>

                  <div className='card-toolbar'>
                    <div className='me-n3'>
                    </div>
                  </div>
                </div>
                <ChatInner
                  ticket={activeTicket}
                  onTicketClick={onTicketClick}
                  ticketChats={ticketChats?.ticket_chats?.reverse()}
                />
              </div>
            )}
          </div>
        </div> */}
      <div className='row g-6 g-xl-9'>
        {data?.map((item: any, index: number) => (
          <div className='col-md-4 col-xxl-4'>
            <Card1
              avatar='/media/avatars/300-6.jpg'
              name={item.title}
              job={item.description}
              status={item.status}
              totalEarnings='$236,400'
              item={item}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Tickets
