import React from "react";
import { toAbsoluteUrl } from "../../../_transparency/helpers";
import { useLayout } from "../../../_transparency/layout/core";

const Payment = () => {
  const { iframe } = useLayout();
  console.log(iframe)
  return (
    <iframe src={iframe} className="w-75" style={{ height: '70vh', overflow: 'hidden', borderRadius: '10px' }} />
  )
}

export default Payment