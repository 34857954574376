import React, { useState } from 'react'
import { KTIcon } from '../../../_transparency/helpers'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { axiosClientAuth } from '../../utils'
import { DatePicker } from 'antd'
import { useLayout } from '../../../_transparency/layout/core'

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Minimum 3 symbols').required('Name is required'),
  address: Yup.string().min(3, 'Minimum 3 symbols').required('Name is required'),
  // tid: Yup.number().required()
})

const initialValues = {
  name: '',
  address: '',
  vendor_type: '',
  vendor_id: '',
}

enum vendorType {
  default = '',
  tm = 'TM',
  ta = 'TA',
  operator = 'Operator'
}

function VendorListRightToolbar() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const res = await axiosClientAuth.post('/main/ip-address', {
          name: values.name,
          address: values.address,
          vendor_type: values.vendor_type,
          vendor_id: values.vendor_id,
        })
        if (res.data.status) {
          Swal.fire({
            titleText: 'Success!',
            text: 'IP Created Successfully.',
            icon: 'success',
            confirmButtonText: 'Ok! Got It',
          }).then((isConfirm) => {
            if (isConfirm) {
              window.location.reload();
            }
          })
        }
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    },
  })

  const { RangePicker } = DatePicker;

  const { setIpRegistrationDateFilter } = useLayout();

  return (
    <>
      <div className='d-flex align-items-center'>
        <RangePicker
          className='bg-light text-white border-0 shadow-none'
          style={{ height: '40px' }}
          onChange={(value, dateString) => setIpRegistrationDateFilter(dateString)}
        />

        {/* <button
          className='btn btn-sm fw-bold btn-primary ms-3'
          style={{ height: '40px' }}
          onClick={() => {
            setShow(!show)
          }}
        >
          Vendor Registration
        </button> */}
      </div>
    </>
  )
}

export default VendorListRightToolbar
