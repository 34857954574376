import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_transparency/helpers'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { axiosClientAuth } from '../../utils'
import { DatePicker } from 'antd'
import { useLayout } from '../../../_transparency/layout/core'
import RegistrationModal from '../../modules/registration/RegistrationModal'

const loginSchema = Yup.object().shape({})

const initialValues: {
  header: any
  content: any
  previewTemplate: any
  previewTemplateOrg: any
  variableCount: any
  totalVariableCount: any
  isVariablesMapped: boolean[]
  variables: string[]
} = {
  header: '',
  content: '',
  previewTemplate: '',
  previewTemplateOrg: '',
  variableCount: '',
  totalVariableCount: '',
  isVariablesMapped: [],
  variables: [''],
}

function UrlMappinfRightToolbar() {
  const [loading, setLoading] = useState(false)

  const [show, setShow] = useState(false)

  const handleClose = () => { setShow(false); formik.resetForm(); }
  const handleShow = () => setShow(true)

  const [headers, setHeaders] = useState({
    headers_count: {
      totol: 0,
      pending: 0,
      approved: 0,
      rejected: 0,
    },
    headers: [],
  })
  const [templates, setTemplates] = useState({
    templates_count: {
      totol: 0,
      pending: 0,
      approved: 0,
      rejected: 0,
    },
    templates: [],
  })
  const [urls, setUrls] = useState([])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        if (values.header && values.content && values.variables?.length > 0 && values.variables[0]) {
          let flag = false;
          Array.from({ length: values.variableCount }, (_, i) => '').forEach((_, index) => {
            if (values.isVariablesMapped[index]) {
              flag = true;
              return;
            }
          });
          let tempMappedUrls: any = [];
          Array.from({ length: values.variableCount }, (_, i) => '').forEach((_, index) => {
            if (values.isVariablesMapped[index]) {
              tempMappedUrls.push({
                variable_index: index,
                url: values.variables[index]
              })
            }
          });
          if(flag){
            const resp = await axiosClientAuth.post('/main/template-mapping', {
              template: values.content,
              header: values.header,
              mapped_urls: tempMappedUrls
              // Array.from({ length: values.variableCount }, (_, i) => '').map((_, index) => ({
              //   variable_index: index,
              //   url: values.variables[index],
              // })),
            })
            setLoading(false)
            Swal.fire({
              titleText: 'Success!',
              text: 'URL Mapped Successfully.',
              icon: 'success',
              confirmButtonText: 'Ok! Got It',
            }).then((isConfirm) => {
              if (isConfirm) {
                window.location.reload()
              }
            })
          } else {
            Swal.fire({
              titleText: 'All Fields Required !!',
              text: 'Please map atleast one variable',
              icon: 'warning',
              confirmButtonText: 'Ok! Got It',
            });
          }
        } else {
          Swal.fire({
            titleText: 'All Fields Required !!',
            text: 'Please fill in all fields.',
            icon: 'warning',
            confirmButtonText: 'Ok! Got It',
          });
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
        Swal.fire({
          titleText: 'Error!',
          text: `Error`,
          icon: 'success',
          confirmButtonText: 'Ok! Got It',
        })
      }
    },
  })

  const getData = async () => {
    try {
      const mHeaders = await axiosClientAuth.get('/main/headers')
      setHeaders(mHeaders.data.data)
      const mTemplates = await axiosClientAuth.get('/main/templates')
      setTemplates(mTemplates.data.data)
      const maxVarCount = mTemplates.data?.data?.templates.reduce((prev: any, current: any) =>
        prev.variable_count > current.variable_count ? prev : current
      )
      formik.setValues((prev) => ({
        ...prev,
        variables: Array.from({ length: parseInt(maxVarCount?.variable_count) }, (_, i) => ''),
        isVariablesMapped: Array.from({ length: parseInt(maxVarCount?.variable_count) }, (_, i) => false),
      }))
      console.log(maxVarCount)
      const mUrls = await axiosClientAuth.get('/main/urls')
      setUrls(mUrls.data.data?.urls)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  console.log(formik.values)

  const onSetPreview = (item) => {
    if (formik.values.variables[item]) {
      const mVar = `{#var#}`;
      const mVal: any = urls.filter((ele: any) => ele.uuid == formik.values.variables[item])
      if (mVal.length > 0) {
        if(formik.values.previewTemplate.indexOf(mVal[0].url) < 0) {
          let i = parseInt(item)+1;
          let mIndex = 0;
          if(i > 0){
            while (i--) {
              mIndex = formik.values.previewTemplateOrg.toString().indexOf(mVar, mIndex + mVar.length);
            }
          }
          let moreIndex = 0;
          for(let t = item; t >= 0; t--) {
            if(formik.values.isVariablesMapped[t]){
              let temp: any = urls.filter((ele: any) => ele.uuid == formik.values.variables[t])
              moreIndex += temp[0].url?.length
              moreIndex -= mVar.length;
            }
          }
          let tempPrev = formik.values.previewTemplate.toString().substring(0, mIndex+moreIndex) + mVal[0].url + formik.values.previewTemplate.toString().substring(mIndex + moreIndex + mVar.length);
          let temp2 = formik.values.isVariablesMapped;
          temp2[item] = true;
          formik.setValues((prev) => ({ ...prev, previewTemplate: tempPrev, isVariablesMapped: temp2 }))
        }
      }
    }
  }

  const onUnSetPreview = (item) => {
    if (formik.values.variables[item]) {
      let temp2 = formik.values.isVariablesMapped;
      temp2[item] = false;
      const mVar = `{#var#}`
      const mVal: any = urls.filter((ele: any) => ele.uuid == formik.values.variables[item])
      if (mVal.length > 0) {
        let tempPrev = formik.values.previewTemplate.toString().replace(mVal[0].url, mVar)
        formik.setValues((prev) => ({ ...prev, previewTemplate: tempPrev, isVariablesMapped: temp2 }))
      }
    }
  }

  const { RangePicker } = DatePicker;

  const { isRegistred, setUrlMappingDateFilter } = useLayout();

  return (
    <>
      <div className='d-flex align-items-center'>
        <RangePicker
          className='bg-light text-white border-0 shadow-none'
          style={{ height: '40px' }}
          onChange={(value, dateString) => setUrlMappingDateFilter(dateString)}
        />

        <button
          className='btn btn-sm fw-bold btn-primary ms-3'
          style={{ height: '40px' }}
          onClick={handleShow}
        >
          URL Mapping
        </button>
      </div>

      {isRegistred
        ? <Modal
          className='modal-sticky modal-ld modal-sticky-lg modal-sticky-bottom-right'
          show={show}
          animation={true}
          centered={true}
        >
          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='d-flex justify-content-end'>
              <button
                className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
                onClick={handleClose}
              >
                <KTIcon iconName='cross' className='text-gray fs-1' />
              </button>
            </div>

            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
              <div className='mb-13 text-center'>
                <h1 className='mb-3'>URL Mapping</h1>
                <div className='text-muted fw-semibold fs-5'>
                  If you need more info, please check{' '}
                  <a href='#' className='fw-bold link-primary'>
                    Our Guidelines
                  </a>
                  .
                </div>
              </div>

              {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              ) : null}

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Header</label>
                <select
                  className='form-select form-select-sm form-select-solid'
                  onChange={(e: any) => {
                    if (e.target.value) {
                      formik.setValues((prev) => ({
                        ...prev,
                        header: e.target.value,
                      }))
                    } else {
                      formik.setValues((prev) => ({ ...prev, header: '' }))
                    }
                  }}
                >
                  <option value=''>Select Header ID</option>
                  {headers?.headers?.map((item: any) => (
                    <option value={item.uuid}>{item.name}</option>
                  ))}
                </select>
              </div>

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Content ID</label>
                <select
                  className='form-select form-select-sm form-select-solid'
                  onChange={(e: any) => {
                    if (e.target.value) {
                      let res: any
                      res = templates.templates.find((ele: any) => (ele.cid == e.target.value))
                      if (res) {
                        formik.setValues((prev) => ({
                          ...prev,
                          previewTemplate: res?.content,
                          previewTemplateOrg: res?.content,
                          totalVariableCount: res?.variable_count,
                          content: res?.uuid,
                        }))
                      }
                    } else {
                      formik.setValues((prev) => ({
                        ...prev,
                        previewTemplate: '',
                        previewTemplateOrg: '',
                        totalVariableCount: '',
                        content: '',
                      }))
                    }
                  }}
                >
                  <option value=''>Select Content ID</option>
                  {templates?.templates?.map((item: any) => (
                    <option value={item.cid}>{item.cid}</option>
                  ))}
                </select>
              </div>

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>
                  No. of Variables
                </label>
                <select
                  className='form-select form-select-sm form-select-solid'
                  onChange={(e: any) => {
                    if (e.target.value) {
                      formik.setValues((prev) => ({
                        ...prev,
                        variableCount: e.target.value,
                      }))
                    } else {
                      formik.setValues((prev) => ({ ...prev, variableCount: '' }))
                    }
                  }}
                >
                  <option value=''>Select No. Of Variables</option>
                  {Array.from(
                    { length: parseInt(formik.values.totalVariableCount) },
                    (_, i) => i + 1
                  )?.map((item: any) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>

              {Array.from({ length: parseInt(formik.values.variableCount) }, (_, i) => i)?.map(
                (item: any) => (
                  <div className='fv-row mb-8 d-flex gap-2'>
                    <select
                      className='form-select form-select-sm form-select-solid'
                      onChange={(e: any) => {
                        let mTemp = formik.values.variables
                        if (e.target.value) {
                          mTemp[item] = e.target.value
                        } else {
                          mTemp[item] = ''
                        }
                        formik.setValues((prev) => ({
                          ...prev,
                          variables: mTemp,
                        }))
                      }}
                    >
                      <option value=''>Select Var {item + 1}</option>
                      {urls?.map((item: any) => (
                        <option value={item.uuid}>{item.url}</option>
                      ))}
                    </select>
                    <button
                      type='button'
                      onClick={() => onSetPreview(item)}
                      className='btn h-41px px-4 btn-icon btn btn-light-primary'
                    >
                      <KTIcon iconName='check' className='text-primary text-hover-white fs-1' />
                    </button>
                    <button type='button' onClick={() => onUnSetPreview(item)} className='btn h-41px px-4 btn-icon btn btn-light-danger'>
                      <KTIcon iconName='cross' className='text-danger text-hover-white fs-1' />
                    </button>
                  </div>
                )
              )}

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>
                  Preview Template
                </label>
                <textarea
                  rows={3}
                  placeholder='Enter Target Details'
                  {...formik.getFieldProps('previewTemplate')}
                  className={clsx('form-control form-control-solid')}
                  name='previewTemplate'
                  autoComplete='off'
                  disabled
                />
              </div>

              {/* begin::Action */}
              <div className='text-center mb-10'>
                <button type='reset' className='btn btn-light me-3' onClick={handleClose}>
                  <span className='indicator-label'>Cancel</span>
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal>
        : <RegistrationModal show={show} handleClose={handleClose} />
      }
    </>
  )
}

export default UrlMappinfRightToolbar