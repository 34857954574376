/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../helpers'
import { axiosClientAuth } from '../../../app/utils'
import moment from 'moment'

type Props = {
  isDrawer?: boolean
  ticket?: any
  ticketChats?: any
  onTicketClick?: any
}

const ChatInner: FC<Props> = ({ isDrawer = false, ticket = {}, ticketChats, onTicketClick }) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const messagesEnd = useRef<any>(null)

  useEffect(() => {
    if (messagesEnd) {
      messagesEnd.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  }, [ticketChats])

  const sendMessage = () => {
    const newMessage = {
      message: message,
      ticket_uuid: ticket?.uuid,
    }

    axiosClientAuth
      .post('/support/chat', newMessage)
      .then((resp) => {
        setMessage('')
        onTicketClick(ticket)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  return (
    <div
      className='card-body'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5', { 'h-300px h-lg-auto': !isDrawer })}
        style={{ minHeight: '60vh' }}
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? '#kt_drawer_chat_messenger_body'
            : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
      >
        {ticketChats?.map((message, index) => {
          const state = message?.sender === 'support' ? 'info' : 'primary'
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.sender}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.sender === 'support' ? 'start' : 'end'} mb-10`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.sender === 'support' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.sender === 'support' ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <div
                          className='d-flex align-items-center justify-content-center symbol symbol-100px symbol-lg-160px symbol-fixed position-relative bg-primary'
                          style={{
                            height: '35px',
                            width: '35px',
                            fontSize: '20px',
                          }}
                        >
                          S
                        </div>
                      </div>
                      <div className='ms-3'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                        >
                          Support
                        </a>
                        <br />
                        <span className='text-muted fs-7 mb-1'>
                          {moment(message.created_at).format('DD MMM YYYY LT')}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                        >
                          You
                        </a>
                        <br />
                        <span className='text-muted fs-7 mb-1'>
                          {moment(message.created_at).format('DD MMM YYYY LT')}
                        </span>
                      </div>
                      <div className='symbol symbol-35px'>
                        <div
                          className='d-flex align-items-center justify-content-center symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'
                          style={{
                            height: '35px',
                            width: '35px',
                            backgroundColor: '#E8912D',
                            fontSize: '20px',
                          }}
                        >
                          {ticket?.created_by?.name?.substr(0, 1)?.toUpperCase()}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    'p-5 rounded',
                    `bg-light-${state}`,
                    'text-dark fw-bold mw-lg-400px',
                    `text-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                  dangerouslySetInnerHTML={{ __html: message.message }}
                ></div>
              </div>
            </div>
          )
        })}
        <div ref={messagesEnd}></div>
      </div>

      {ticket.status != 'resolved' && <div
        className='card-footer pt-4 px-0'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
          disabled={ticket.status == 'resolved'}
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
              disabled={ticket.status == 'resolved'}
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
            disabled={ticket.status == 'resolved'}
          >
            Send
          </button>
        </div>
      </div>}
    </div>
  )
}

export { ChatInner }