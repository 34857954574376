import React from 'react'
import {axiosClientAuth} from '../../../../../app/utils'
import Swal from 'sweetalert2'

const PopupURLMapping = ({status, uuid}) => {
  
  const onStatusChanged = (type) => {
    axiosClientAuth
      .patch('/main/template-mapping', {uuid, status: type})
      .then((resp) => {
        Swal.fire({
          titleText: 'Success!',
          text: 'URL Mapping Updated Successfully.',
          icon: 'success',
          confirmButtonText: 'Ok! Got It',
        }).then((isConfirm) => {
          if (isConfirm) {
            window.location.reload()
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className='ps-4 py-3'>
        <div className='fs-5 text-dark fw-bolder'>Quick Actions</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='menu-item px-5 text-center'>
        <button
          type='button'
          onClick={() => onStatusChanged(true)}
          className={`btn btn-link ${Boolean(status) ? 'disabled' : ''}`}
        >
          Enable
        </button>
      </div>

      <div className='menu-item px-5 text-center'>
        <button
          type='button'
          onClick={() => onStatusChanged(false)}
          className={`btn btn-link ${Boolean(status) ? '' : 'disabled'}`}
        >
          Disable
        </button>
      </div>
    </>
  )
}

export default PopupURLMapping
