import React from "react";

const FAQ = () => {
  return (
    <div className="card">
      <div className="card-body p-10 p-lg-15">
        <div className="mb-13">
          <div className="mb-15">
            <h4 className="fs-2x text-gray-800 w-bolder mb-6">
              Frequently Asked Questions
            </h4>
            <p className="fw-semibold fs-4 text-gray-600 mb-2">
              First, a disclaimer – the entire process of writing a blog post often
              takes more than a couple of hours, even if you can type eighty words
              as per minute and your writing skills are sharp.
            </p>
          </div>
          <div className="row mb-12">
            <div className="col-md-6 pe-md-10 mb-10 mb-md-0">
              <h2 className="text-gray-800 fw-bold mb-4">Buying Product</h2>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_4_1"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How does it work?
                  </h4>
                </div>
                <div id="kt_job_4_1" className="collapse show fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_4_2"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    Do I need a designer to use Admin Theme ?
                  </h4>
                </div>
                <div id="kt_job_4_2" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_4_3"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    What do I need to do to start selling?
                  </h4>
                </div>
                <div id="kt_job_4_3" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_4_4"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How much does Extended license cost?
                  </h4>
                </div>
                <div id="kt_job_4_4" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ps-md-10">
              <h2 className="text-gray-800 fw-bold mb-4">Installation</h2>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_5_1"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    What platforms are compatible?
                  </h4>
                </div>
                <div id="kt_job_5_1" className="collapse show fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_5_2"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How many people can it support?
                  </h4>
                </div>
                <div id="kt_job_5_2" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_5_3"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How long is the warranty?
                  </h4>
                </div>
                <div id="kt_job_5_3" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_5_4"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How fast is the installation?
                  </h4>
                </div>
                <div id="kt_job_5_4" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 pe-md-10 mb-10 mb-md-0">
              <h2 className="text-gray-800 w-bolder mb-4">User Roles</h2>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_6_1"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How does it work?
                  </h4>
                </div>
                <div id="kt_job_6_1" className="collapse show fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_6_2"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    Do I need a designer to use this Admin Theme?
                  </h4>
                </div>
                <div id="kt_job_6_2" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_6_3"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    What do I need to do to start selling?
                  </h4>
                </div>
                <div id="kt_job_6_3" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_6_4"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How much does Extended license cost?
                  </h4>
                </div>
                <div id="kt_job_6_4" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ps-md-10">
              <h2 className="text-gray-800 fw-bold mb-4">Reports Generation</h2>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_7_1"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    What platforms are compatible?
                  </h4>
                </div>
                <div id="kt_job_7_1" className="collapse show fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_7_2"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How many people can it support?
                  </h4>
                </div>
                <div id="kt_job_7_2" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_7_3"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How long is the warrianty?
                  </h4>
                </div>
                <div id="kt_job_7_3" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
                <div className="separator separator-dashed" />
              </div>
              <div className="m-0">
                <div
                  className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_job_7_4"
                >
                  <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                    <span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="6.0104"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="svg-icon toggle-off svg-icon-1">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={5}
                          fill="currentColor"
                        />
                        <rect
                          x="10.8891"
                          y="17.8033"
                          width={12}
                          height={2}
                          rx={1}
                          transform="rotate(-90 10.8891 17.8033)"
                          fill="currentColor"
                        />
                        <rect
                          x="6.01041"
                          y="10.9247"
                          width={12}
                          height={2}
                          rx={1}
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </div>
                  <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                    How fast is the installation?
                  </h4>
                </div>
                <div id="kt_job_7_4" className="collapse fs-6 ms-1">
                  <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                    First, a disclaimer – the entire process of writing a blog post
                    often takes more than a couple of hours, even if you can type
                    eighty words as per minute and your writing skills are sharp.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ