import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_transparency/helpers'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { axiosClientAuth } from '../../utils'
import { DatePicker } from 'antd'
import { useLayout } from '../../../_transparency/layout/core'
import RegistrationModal from '../../modules/registration/RegistrationModal'

export default function MyIPRightToolbar() {
  const [loading, setLoading] = useState(false)

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const formik = useFormik({
    initialValues: { name: '', address: '' },
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const res = await axiosClientAuth.post('/main/pe-ip', values)
        setLoading(false)
        Swal.fire({
          titleText: 'Success!',
          text: 'IP Created Successfully.',
          icon: 'success',
          confirmButtonText: 'Ok! Got It',
        }).then((isConfirm) => {
          if (isConfirm) {
            window.location.reload()
          }
        })
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    },
  })

  const { isRegistred, setMyIpDateFilter } = useLayout();

  return (
    <>
      <div className='d-flex align-items-center'>
        <DatePicker.RangePicker
          className='bg-light text-white border-0 shadow-none'
          style={{ height: '40px' }}
          onChange={(value, dateString) => setMyIpDateFilter(dateString)}
        />
        <button
          className='btn btn-sm fw-bold btn-primary ms-3'
          style={{ height: '40px' }}
          onClick={handleShow}
        >
          Add IP
        </button>
      </div>

      {isRegistred
        ? <Modal
          className='modal-sticky modal-ld modal-sticky-lg modal-sticky-bottom-right'
          show={show}
          animation={true}
          centered={true}
        >
          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='d-flex justify-content-end'>
              <button
                className='ms-auto btn btn-sm btn-icon btn-active-color-primary'
                onClick={handleClose}
              >
                <KTIcon iconName='cross' className='text-gray fs-1' />
              </button>
            </div>

            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
              <div className='mb-13 text-center'>
                <h1 className='mb-3'>Add IP</h1>
                <div className='text-muted fw-semibold fs-5'>
                  If you need more info, please check{' '}
                  <a href='#' className='fw-bold link-primary'>
                    Our Guidelines
                  </a>
                  .
                </div>
              </div>

              {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              ) : null}

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>IP Name</label>
                <input
                  placeholder='Enter IP Name'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>

              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark required'>IP Address</label>
                <input
                  placeholder='Enter IP Address'
                  {...formik.getFieldProps('address')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.address && formik.errors.address },
                    {
                      'is-valid': formik.touched.address && !formik.errors.address,
                    }
                  )}
                  type='text'
                  name='address'
                  autoComplete='off'
                />
                {formik.touched.address && formik.errors.address && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.address}</span>
                  </div>
                )}
              </div>

              {/* begin::Action */}
              <div className='text-center mb-10'>
                <button type='reset' className='btn btn-light me-3' onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}>
                  <span className='indicator-label'>Cancel</span>
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal>
        : <RegistrationModal show={show} handleClose={handleClose} />
      }
    </>
  )
}