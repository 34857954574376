import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MyProfileTabs } from '../../../pages/myProfile/MyProfile'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const OverviewMyProfile = ({ data, setTab }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Profile Details</h3>
          </div>
          <a
            href='javascript:void(0)'
            className='btn btn-sm btn-primary align-self-center'
            onClick={() => setTab(MyProfileTabs.settings)}
          >
            Edit Profile
          </a>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>PE ID</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-semibold text-gray-800 fs-6'>{data?.pe_id}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Full Name</label>
            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-gray-800'>{data?.name}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>
              Email
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id='tooltip-myprofile-email'>
                    Email must be active
                  </Tooltip>
                }
              >
                <i className='fas fa-exclamation-circle ms-1 fs-7' />
              </OverlayTrigger>
            </label>
            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.email}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>
              Contact Number
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id='tooltip-myprofile-mobilenumber'>
                    Mobile number must be active
                  </Tooltip>
                }
              >
                <i className='fas fa-exclamation-circle ms-1 fs-7' />
              </OverlayTrigger>
            </label>
            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.phone}</span>
            </div>
          </div>
          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Communication</label>
            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-gray-800'>Email, Phone</span>
            </div>
          </div>
          <div className='row mb-10'>
            <label className='col-lg-4 fw-semibold text-muted'>Allow Changes</label>
            <div className='col-lg-8'>
              <span className='fw-semibold fs-6 text-gray-800'>Yes</span>
            </div>
          </div> */}
          <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <span className='svg-icon svg-icon-2tx svg-icon-warning me-4'>
              <svg
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.3'
                  x={2}
                  y={2}
                  width={20}
                  height={20}
                  rx={10}
                  fill='currentColor'
                />
                <rect
                  x={11}
                  y={14}
                  width={7}
                  height={2}
                  rx={1}
                  transform='rotate(-90 11 14)'
                  fill='currentColor'
                />
                <rect
                  x={11}
                  y={17}
                  width={2}
                  height={2}
                  rx={1}
                  transform='rotate(-90 11 17)'
                  fill='currentColor'
                />
              </svg>
            </span>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-semibold'>
                <h4 className='text-gray-900 fw-bold'>We need your attention!</h4>
                <div className='fs-6 text-gray-700'>
                  Your payment was declined. To start using tools, please
                  <a className='fw-bold' href='javascript:void(0)' onClick={() => setTab(MyProfileTabs.billing)}>
                    Add Payment Method
                  </a>
                  .
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='row gy-5 g-xl-10'>
        <div className='col-xl-8'>
          <div className='card card-flush h-xl-100'>
            <div className='card-header pt-7'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-dark'>SMS Report</span>
                <span className='text-gray-400 mt-1 fw-semibold fs-6'>Recent 5 SMS details</span>
              </h3>
              <div className='card-toolbar'>
                <div className='d-flex flex-stack flex-wrap gap-4'>
                  <a
                    href='javascript:void(0)'
                    className='btn btn-light btn-sm'
                    onClick={() => navigate('/dashboard')}
                  >
                    View All SMSs
                  </a>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-3'
                id='kt_table_widget_5_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-60px'>Header</th>
                    <th className='pe-3 min-w-100px'>CONTENT</th>
                    <th className='pe-3 min-w-150px'>DATE</th>
                    <th className='pe-3 min-w-100px'>SENT TO</th>
                    <th className='pe-3 min-w-50px'>STATUS</th>
                  </tr>
                </thead>
                <tbody className='fw-bold text-gray-600'>
                  <tr>
                    <td>Test</td>
                    <td>
                      Thank you for showing interest, for more information please click on below
                      link 7798406610 Sanjay
                    </td>
                    <td>02 Apr, 2022</td>
                    <td>9104210103</td>
                    <td>
                      <span className='badge py-3 px-4 fs-7 badge-light-danger'>failed</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Test</td>
                    <td>
                      Thank you for showing interest, for more information please click on below
                      link 7798406610 Sanjay
                    </td>
                    <td>02 Apr, 2022</td>
                    <td>9104210103</td>
                    <td>
                      <span className='badge py-3 px-4 fs-7 badge-light-danger'>failed</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Test</td>
                    <td>
                      Thank you for showing interest, for more information please click on below
                      link 7798406610 Sanjay
                    </td>
                    <td>02 Apr, 2022</td>
                    <td>9104210103</td>
                    <td>
                      <span className='badge py-3 px-4 fs-7 badge-light-danger'>failed</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Test</td>
                    <td>
                      Thank you for showing interest, for more information please click on below
                      link 7798406610 Sanjay
                    </td>
                    <td>02 Apr, 2022</td>
                    <td>9104210103</td>
                    <td>
                      <span className='badge py-3 px-4 fs-7 badge-light-danger'>failed</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Test</td>
                    <td>
                      Thank you for showing interest, for more information please click on below
                      link 7798406610 Sanjay
                    </td>
                    <td>02 Apr, 2022</td>
                    <td>9104210103</td>
                    <td>
                      <span className='badge py-3 px-4 fs-7 badge-light-success'>Success</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='col-xl-4'>
          <div className='card h-md-100' dir='ltr'>
            <div className='card-body d-flex flex-column flex-center'>
              <div className='mb-2'>
                <h1 className='fw-semibold text-gray-800 text-center lh-lg'>
                  Have you tried
                  <br />
                  our
                  <span className='fw-bolder'> Pro Plan ?</span>
                </h1>
                <div className='py-10 text-center'>
                  <img
                    src='/media/svg/illustrations/easy/1.svg'
                    className='theme-light-show w-200px'
                    alt=''
                  />
                  <img
                    src='/media/svg/illustrations/easy/1-dark.svg'
                    className='theme-dark-show w-200px'
                    alt=''
                  />
                </div>
              </div>
              <div className='text-center mb-1'>
                <a
                  className='btn btn-sm btn-primary me-2'
                  href="/smspack"
                >
                  Try now
                </a>
                <a className='btn btn-sm btn-light' href='../apps/invoices/view/invoice-1.html'>
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default OverviewMyProfile
