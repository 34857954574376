/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../helpers'
import { useLayout } from '../../../../layout/core'
import { useRegistration } from '../../../../../app/utils/customHooks/useRegistration'

type Props = {
  className: string
}

const EngageWidgetDashboard = ({ className }: Props) => {
  const { isRegistred } = useLayout()

  const navigate = useNavigate()

  const { registerNow } = useRegistration()

  return (
    <div
      className={`card border-transparent card-flush ${className}`}
      style={{ backgroundColor: "#1C325E" }}
    >
      <div className="card-body d-flex ps-xl-15">
        {isRegistred
          ? <div className="m-0">
            <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
              <span className="me-2">
                Start Your Journey
                <br />
                {/* <span className="position-relative d-inline-block text-danger">
                  <a
                    href="javascript:void(0)"
                    className="text-danger opacity-75-hover"
                  >
                    Premium Plan
                  </a>
                  <span className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100" />
                </span> */}
              </span>
              Whitelist Your URL Today!
            </div>
            <div className="mb-3">
              <a
                href="javascript:void(0)"
                className="btn btn-danger fw-semibold me-2"
                onClick={() => navigate('/smspack')}
              >
                Whitelist Now
              </a>
            </div>
          </div>
          : <div className="m-0">
            <div className="position-relative fs-2x z-index-2 fw-bold text-white mb-7">
              <span className="me-2">
                Join us today and kickstart your
                <br />
                journey with our secure &nbsp;
                <span className="position-relative d-inline-block text-danger">
                  <a
                    href="javascript:void(0)"
                    className="text-danger opacity-75-hover"
                    onClick={() => navigate('/smspack')}
                  >
                    SMS service!
                  </a>
                  <span className="position-absolute opacity-50 bottom-0 start-0 border-4 border-danger border-bottom w-100" />
                </span>
              </span>
            </div>
            <div className="mb-3">
              <a
                href="javascript:void(0)"
                className="btn btn-danger fw-semibold me-2"
                onClick={registerNow}
              >
                Register Now
              </a>
            </div>
          </div>
        }
        <img
          src={toAbsoluteUrl('/media/illustrations/sigma-1/17-dark.png')}
          className="position-absolute me-3 bottom-0 end-0 h-200px"
          alt=""
        />
      </div>
    </div>
  )
}

export { EngageWidgetDashboard }