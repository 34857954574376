import React, { FC, useEffect, useState } from 'react'
import { PageLink, PageTitle, useLayout } from '../../../_transparency/layout/core'
import { KTIcon } from '../../../_transparency/helpers'
import { CardsWidget171 } from '../../../_transparency/partials/widgets/_new/cards/CardsWidget171'
import { EngageWidget10 } from '../../../_transparency/partials/widgets'
import { axiosClientAuth } from '../../utils'
import moment from 'moment'
import TableWidgetIpList from '../../../_transparency/partials/widgets/_new/tables/TablesWidgetIpList'

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const VendorList = () => {
  const { setRightTolbar, ipRegistrationDateFilter } = useLayout()

  useEffect(() => {
    setRightTolbar('ipregistration')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>Vendor List</PageTitle>

      <div className='row'>
        <div className='col-12'>
          <TableWidgetIpList />
        </div>
      </div>
    </>
  )
}

const VendorListWrapper: FC = () => {
  const { setRightTolbar } = useLayout()

  useEffect(() => {
    setRightTolbar('vendorlist')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>Vendor List</PageTitle>
      <VendorList />
    </>
  )
}

export default VendorListWrapper