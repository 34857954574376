/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../../helpers'
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import moment from 'moment'

type Props = {
  className: string
}

const tableData = [
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'resolved',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: true
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
  {
    number: '919104210103',
    message: 'As we turn 2 in India, visit the American Eagle store and get a {#var#} OFF, exclusively for you! Limited period offer. *T&C apply– American Eagle',
    header: 'AMEEAG',
    date: new Date(),
    operator: 'Jio',
    status: 'pending',
    oap: 'Jio',
    tap: 'Jio',
    existingLog: false
  },
];

type columnType = {
  number: string,
  message: string,
  header: string,
  date: Date,
  operator: string,
  status: string,
  oap: string,
  tap: string,
  existingLog: boolean
}

const columns: ReadonlyArray<Column<columnType>> = [
  { Header: 'NUMBER', accessor: 'number' },
  { Header: 'MESSAGE', accessor: 'message' },
  { Header: 'HEADER', accessor: 'header' },
  {
    Header: 'DATE',
    accessor: 'date',
    Cell: ({ ...props }) => {
      return (
        <>
          <span>{moment(props.value).format('DD MMM YYYY')}</span>
          <span className='d-block text-muted fw-semibold'>{moment(props.value).format('LT')}</span>
        </>
      )
    },
  },
  { Header: 'OPERATOR', accessor: 'operator' },
  {
    Header: 'STATUS',
    accessor: 'status',
    Cell: ({ ...props }) => {
      return props.value === 'resolved' ? (
        <span className='badge badge-light-success fw-bold px-4 py-3'>resolved</span>
      ) : (
        <span className='badge badge-light-warning fw-bold px-4 py-3'>pending</span>
      )
    },
  },
  { Header: 'OAP', accessor: 'oap' },
  { Header: 'TAP', accessor: 'tap' },

  {
    Header: 'EXISTING LOG',
    accessor: 'existingLog',
    Cell: ({ ...props }) => {
      return props.value ? (
        <button className='btn h-35px btn-icon btn btn-light-success me-3'>
          <KTIcon iconName='check' className='text-success text-hover-white fs-1' />
        </button>
      ) : (
        <button className='btn h-35px btn-icon btn btn-light-danger'>
          <KTIcon iconName='cross' className='text-danger text-hover-white fs-1' />
        </button>
      )
    },
  },
]

const TablesWidgetComplaintsLogs: React.FC<Props> = ({ className }) => {

  const complaintsLogsTable = useTable(
    {
      columns,
      data: tableData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>UCC Complaints Logs</span>
          <span className='text-muted fw-semibold fs-7'>Total 63 Complaints</span>
        </h3>
        <div className='d-flex align-items-center'>
          <select
            className='form-select form-select-sm form-select-solid me-2'
            style={{ minWidth: '120px' }}
            value={complaintsLogsTable.headers[2].filterValue}
            onChange={(e) => complaintsLogsTable.headers[2].setFilter(e.target.value)}
          >
            {['All Header', 'AMEEAG'].map((item) => (
              <option value={item == 'All Header' ? '' : item}>{item}</option>
            ))}
          </select>
          <select
            className='form-select form-select-sm form-select-solid me-2'
            style={{ minWidth: '130px' }}
            value={complaintsLogsTable.headers[4].filterValue}
            onChange={(e) => complaintsLogsTable.headers[4].setFilter(e.target.value)}
          >
            {['All Operator', 'Jio'].map((item) => (
              <option value={item == 'All Operator' ? '' : item}>{item}</option>
            ))}
          </select>
          <select
            className='form-select form-select-sm form-select-solid'
            value={complaintsLogsTable.headers[5].filterValue}
            onChange={(e) => complaintsLogsTable.headers[5].setFilter(e.target.value)}
          >
            {['All Status', 'resolved', 'pending'].map((item) => (
              <option value={item == 'All Status' ? '' : item}>{item}</option>
            ))}
          </select>
          <div className='d-flex align-items-center position-relative my-1 ms-2'>
            <KTIcon iconName='magnifier' className='text-white-500 position-absolute ms-2' />
            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search Order'
              value={complaintsLogsTable.state.globalFilter}
              onChange={(e) => complaintsLogsTable.setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        <div className='table-responsive'>
          <table
            {...complaintsLogsTable.getTableProps()}
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              {complaintsLogsTable.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={column.render('Header') == 'DATE' ? 'w-150px' : ''}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className='text-gray-400 fs-7'>{column.render('Header')}</span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KTIcon iconName='down' className='text-gray ms-1' />
                        ) : (
                          <KTIcon iconName='up' className='text-gray ms-1' />
                        )
                      ) : (
                        ''
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...complaintsLogsTable.getTableBodyProps()}>
              {(complaintsLogsTable.page.length > 0 &&
                complaintsLogsTable.page.map((row) => {
                  complaintsLogsTable.prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td className='fw-bold' {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  )
                })) || (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <span className='fw-bold'>No data available in table</span>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          <div className='d-flex justify-content-between align-items-center mt-5'>
            <div>
              <select
                className='form-select form-select-sm form-select-solid'
                value={complaintsLogsTable.state.pageSize}
                onChange={(e) => complaintsLogsTable.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {complaintsLogsTable.state.pageIndex + 1} of {complaintsLogsTable.pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <button
                className='btn btn-sm'
                onClick={() => complaintsLogsTable.previousPage()}
                disabled={!complaintsLogsTable.canPreviousPage}
              >
                <KTIcon iconName='left' className='text-gray fs-1 me-5' />
              </button>
              <button
                className='btn btn-sm'
                onClick={() => complaintsLogsTable.nextPage()}
                disabled={!complaintsLogsTable.canNextPage}
              >
                <KTIcon iconName='right' className='text-gray fs-1 me-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { TablesWidgetComplaintsLogs }