import { createRoot } from 'react-dom/client'
import './index.css'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
// Apps
import { MetronicI18nProvider } from './_transparency/i18n/Metronici18n'
import './_transparency/assets/fonticon/fonticon.css'
import './_transparency/assets/keenicons/duotone/style.css'
import './_transparency/assets/keenicons/outline/style.css'
import './_transparency/assets/keenicons/solid/style.css'
import './_transparency/assets/sass/style.scss'
import './_transparency/assets/sass/plugins.scss'
import './_transparency/assets/sass/style.react.scss'
import './index.css'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'


setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MetronicI18nProvider>
    </QueryClientProvider>
  )
}
