/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_transparency/helpers'
import { PageLink, PageTitle, useLayout } from '../../../_transparency/layout/core'
import { TablesWidgetOrders } from '../../../_transparency/partials/widgets/_new/tables/TablesWidgetOrders'
import EngageWidgetMyProfile from '../../../_transparency/partials/widgets/_new/engage/EngageWidgetMyProfile'
import OverviewMyProfile from '../../modules/apps/myprofile/OverviewMyProfile'
import MyProfileSettings from '../../modules/apps/myprofile/MyProfileSettings'
import BillingMyPorfile from '../../modules/apps/myprofile/BillingMyProfile'
import { axiosClientAuth } from '../../utils'

export enum MyProfileTabs {
  'overview' = 'overview',
  'settings' = 'settings',
  'billing' = 'billing',
}

const MyProfile: FC = () => {
  const [tab, setTab] = useState(MyProfileTabs.overview)
  const [data, setData] = useState<any>({})
  const [addressData, setAddressData] = useState<any>({})
  const [preferences, setPreferences] = useState<any>()
  const [tabComponent, setTabComponent] = useState(<></>)

  const getData = () => {
    axiosClientAuth
      .get('/auth/user-profile')
      .then((res) => {
        setData(res.data?.data);
      })
      .catch((err) => console.log(err))

    axiosClientAuth
      .get('/common/notification-preference')
      .then((res) => setPreferences(res.data?.data))
      .catch((err) => console.log(err))
  }

  const getAddressData = () => {
    axiosClientAuth
      .get('/auth/address')
      .then((resp) => setAddressData(resp.data?.data))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getData()
    getAddressData()
  }, [])

  useEffect(() => {
    if (data?.uuid) {
      switch (tab) {
        case MyProfileTabs.overview:
          setTabComponent(<OverviewMyProfile setTab={setTab} data={data} />)
          break
        case MyProfileTabs.settings:
          setTabComponent(<MyProfileSettings data={data} preferences={preferences} />)
          break
        case MyProfileTabs.billing:
          setTabComponent(<BillingMyPorfile addressData={addressData} profileData={data} />)
          break
      }
    }
  }, [tab, data])

  return (
    <>
      <EngageWidgetMyProfile tab={tab} data={data} setTab={setTab} />
      {tabComponent}
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MyProfileWrapper: FC = () => {
  const intl = useIntl();

  const { setRightTolbar } = useLayout();

  useEffect(() => {
    setRightTolbar("myprofile")
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>{intl.formatMessage({ id: 'My Profile' })}</PageTitle>
      <MyProfile />
    </>
  )
}

export { MyProfileWrapper }
