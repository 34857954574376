/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from 'react'
import { KTIcon } from '../../../../helpers'
import { getCSS, getCSSVariableValue } from '../../../../assets/ts/_utils'
import { useThemeMode } from '../../../layout/theme-mode/ThemeModeProvider'
import ApexCharts from 'apexcharts'

type Props = {
  className: string
  chartSize?: number
  mLables?: Object
  mColors?: Object
  heading?: string
  subHeading?: string
}

const CardsWidget171: FC<Props> = ({
  className,
  chartSize = 210,
  mLables = {},
  mColors = {},
  heading = '',
  subHeading = '',
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, mLables])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height')) + 10

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, chartSize, mLables, mColors)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  let sumOfLabels = 0
  Object.values(mLables).forEach((item) => {
    sumOfLabels += parseInt(item)
  })

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            {/* <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span> */}

            <span className='fs-1 fw-bold text-dark me-2 lh-1 ls-n2'>{heading}</span>

            {/* <span className='badge badge-light-success fs-base'>
              <KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' /> 2.2%
            </span> */}
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>
            Total {sumOfLabels} {subHeading} Registred
          </span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center h-175px w-175px pt-2 position-relative me-15 mb-7'>
          <div className='position-absolute translate-middle start-50 top-50 d-flex flex-column flex-center'>
            <span className='fs-2qx fw-bold'>{sumOfLabels}</span>
            <span className='fs-6 fw-semibold text-gray-400'>Total {subHeading}</span>
          </div>
          {/* <div
            id='kt_card_widget_17_chart'
            ref={chartRef}
            style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div> */}
          <div
            ref={chartRef}
            className='mt-2'
            id='kt_card_widget_17_chart'
            style={{ height: chartSize + 'px' }}
          />
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid pe-11 mb-5'>
          {Object.keys(mLables).map((key) => (
            <div className='d-flex fw-semibold align-items-center my-2'>
              <div className={`bullet w-8px h-3px rounded-2 ${mColors[key]} me-3`}></div>
              <div className='text-gray-500 flex-grow-1 me-4'>{key}</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>{mLables[key]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function getChartOptions(height: number, width: number, mLables: any, mColors: Object) {
  console.log(mLables, mColors, height, width)
  return {
    series: Object.values(mLables),
    labels: Object.keys(mLables),
    colors: Object.values(mColors).map((item) => {
      return getCSSVariableValue(`--${item?.toString().replace('bg', 'bs')}`)
    }),
    chart: {
      height,
      width,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height,
            width,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  }
}

// const initChart = function (
//   chartSize: number = 70,
//   chartLine: number = 11,
//   chartRotate: number = 145
// ) {
//   const el = document.getElementById('kt_card_widget_17_chart')
//   if (!el) {
//     return
//   }
//   el.innerHTML = ''

//   var options = {
//     size: chartSize,
//     lineWidth: chartLine,
//     rotate: chartRotate,
//     //percent:  el.getAttribute('data-kt-percent') ,
//   }

//   const canvas = document.createElement('canvas')
//   const span = document.createElement('span')

//   // @ts-ignore
//   if (typeof G_vmlCanvasManager !== 'undefined') {
//     // @ts-ignore
//     G_vmlCanvasManager.initElement(canvas)
//   }

//   const ctx = canvas.getContext('2d')
//   canvas.width = canvas.height = options.size

//   el.appendChild(span)
//   el.appendChild(canvas)

//   // @ts-ignore
//   ctx.translate(options.size / 2, options.size / 2) // change center
//   // @ts-ignore
//   ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

//   //imd = ctx.getImageData(0, 0, 240, 240);
//   const radius = (options.size - options.lineWidth) / 2

//   const drawCircle = function (color: string, lineWidth: number, percent: number) {
//     percent = Math.min(Math.max(0, percent || 1), 1)
//     if (!ctx) {
//       return
//     }

//     ctx.beginPath()
//     ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
//     ctx.strokeStyle = color
//     ctx.lineCap = 'round' // butt, round or square
//     ctx.lineWidth = lineWidth
//     ctx.stroke()
//   }

//   // Init 2
//   const total = 64+3+55;
//   drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, 1)
//   drawCircle(getCSSVariableValue('--bs-danger'), options.lineWidth, 0.5)
//   drawCircle(getCSSVariableValue('--bs-warning'), options.lineWidth, 0.3)
// }

export { CardsWidget171 }
