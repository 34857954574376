/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_transparency/helpers'
import { PageLink, PageTitle, useLayout } from '../../../_transparency/layout/core'
import { TablesWidgetOrders } from '../../../_transparency/partials/widgets/_new/tables/TablesWidgetOrders'

const OrdersList: FC = () => (
  <>
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-12'>
        <TablesWidgetOrders className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </div>
  </>
)

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const OrdersListWrapper: FC = () => {
  const intl = useIntl();
  const { setRightTolbar } = useLayout();

  useEffect(() => {
    setRightTolbar("orderlist")
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>{intl.formatMessage({ id: 'MENU.ORDERSLIST' })}</PageTitle>
      <OrdersList />
    </>
  )
}

export { OrdersListWrapper }
