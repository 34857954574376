/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useLayout } from '../../../../layout/core';
import { KTIcon } from '../../../../helpers';
import { axiosClientAuth } from '../../../../../app/utils';

const SideModalIPList: FC = () => {
  const [data, setData] = useState({
    ipaddress: []
  })

  const { uuidOfVendorForIpList } = useLayout()

  useEffect(() => {
    if (uuidOfVendorForIpList) {
      axiosClientAuth.get(`main/user/ip-addresses?vendor_id=${uuidOfVendorForIpList}`)
        .then((res) => {
          console.log(res)
          setData(res.data.data)
        })
        .catch((error) => console.log(error))
    }
  }, [uuidOfVendorForIpList])

  return (
    <div
      id='kt_drawer_ip_list'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_ip_list_toggle'
      data-kt-drawer-close='#kt_drawer_ip_list_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_ip_list_messenger'>
        <div className='card-header pe-5' id='kt_drawer_ip_list_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <h1>IP List</h1>
            </div>
          </div>
          <div className='card-toolbar'>
            <div
              className='btn btn-sm btn-icon btn-active-light-primary'
              id='kt_drawer_ip_list_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </div>
          </div>
        </div>

        <div className='card-body'>
          <div className='timeline'>
            <div className='timeline'>
              {data?.ipaddress?.map((ip: any, index: number) => (
                <div className='timeline-item'>
                  <div className='timeline-line w-40px'></div>

                  <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
                    <div className={`symbol-label bg-light-${ip.is_blacklisted_by_tcil != 'warning' ? (Boolean(ip.is_blacklisted_by_tcil) ? 'danger' : 'success') : 'Pending'}`}>
                      <KTIcon iconName='fasten' className={`fs-2 text-${ip.is_blacklisted_by_tcil != 'warning' ? (Boolean(ip.is_blacklisted_by_tcil) ? 'danger' : 'success') : 'Pending'}`} />
                    </div>
                  </div>

                  <div className='timeline-content mb-10 mt-2 d-flex justify-content-between align-items-start'>
                    <div className='pe-3 mb-5'>
                      <div className='fw-bold text-gray-600 fs-6'>
                        {ip?.name}
                      </div>
                      <div className='fs-5 fw-bold'>
                        {ip?.address}
                      </div>
                      <div className='text-muted fw-semibold'>
                        {ip?.created_at}
                      </div>
                    </div>
                    <div className={`badge badge-light-${ip.is_blacklisted_by_tcil != 'warning' ? (Boolean(ip.is_blacklisted_by_tcil) ? 'danger' : 'success') : 'Pending'}`}>
                      {ip.is_blacklisted_by_tcil != null ? (Boolean(ip.is_blacklisted_by_tcil) ? 'Blacklist' : 'Whitelist') : 'Pending'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SideModalIPList }